<template>
  <div class="container_margin">
    <div class="card p-3">
      <h5><b>{{ "Upload Case File" }}</b></h5>

      <span class="my-1">
        <b>
          Case Document
          <span
            id="tooltip-target-1"
            class="text-danger cursor-pointer mx-1"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-1"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip1"
          title="Required"
        ></b-tooltip>
      </span>

      <div class="row mb-2">
        <div class="col">
          <input
            class="form-control"
            type="file"
            id="formFile"
            name="Case Document"
            v-validate="'required'"
            accept=".pdf"
            @change="handleFileChange"
          />
          <small class="font-12 mx-1">
            <b-icon icon="info-circle" />
            Please upload PDF file only
            <br>
          </small>

          <small class="text-danger">
            {{ errors.first("Case Document") }}
          </small>
        </div>

        <div class="col">
          <input
            class="form-control"
            type="text"
            id="fileName"
            placeholder="File Name"
            v-model.trim="selectedFileName"
            name="File Name"
            v-validate="'required'"
          />
          <small class="font-12 mx-1">
            <b-icon icon="info-circle" />
            Please rename your file
            <br>
          </small>

          <small class="text-danger">
            {{ errors.first("File Name") }}
          </small>
        </div>
      </div>

      <div>
        <button
          @click="handleFileUpload"
          id="buttonFile"
          class="btn btn-primary float-right text-center d-flex px-2"
        >
          <span class="material-icons-outlined">file_upload</span>
          <span class="w-100">{{ "Upload File" }}</span>
        </button>
      </div>
    </div>

    <h5 class="mt-3">
      <b>{{ `Summary Result${ searched_data.length > 1 ? "s" : "" }` }}</b>
    </h5>

    <div class="row mt-2 mb-3">
      <div
        v-if="searched_data.length !== 0 || searchQuery.length !== 0"
        class="col-5"
      >
        <span id="searchCaseLaw" class="input-group">
          <input
            v-model="searchQuery"
            type="search"
            class="form-control"
            placeholder="Search by statement"
            aria-label="Search by statement"
            @input="onInput"
            @keyup.enter="searchClients"
          />
          <span
            class="input-group-text cursor-pointer"
            @click="searchClients"
          >
            <b-icon icon="search" font-scale="1.2" />
          </span>
        </span>
      </div>

      <div v-if="searched_data.length !== 0 && rows > 10" class="col-7">
        <b-pagination
          class="mb-0 float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        ></b-pagination>
      </div>
    </div>

    <table class="table card border shadow-sm p-2 rounded table-card">
      <thead>
        <tr>
          <th scope="col" width="6%">{{ "ID" }}</th>
          <th scope="col" width="45%">{{ "Case File" }}</th>
          <th scope="col" class="text-center" width="15%">
            {{ "Uploaded Date" }}
          </th>
          <th
            scope="col"
            class="text-center"
            width="15%"
            style="padding-right: 15px"
          >
            {{ "Uploaded By" }}
          </th>
          <th
            scope="col"
            class="text-center"
            style="padding-right: 25px"
            width="20%"
          >
            {{ "Action" }}
          </th>
        </tr>
      </thead>

      <tbody>
        <PageLoader
          class="w-100"
          v-if="pageLoading"
          :loading="pageLoading"
        />

        <div
          v-else-if="searched_data.length === 0"
          class="container_margin"
        >
          <div class="my-2 m-auto" style="width: 200px">
            <img src="@/assets/noData.png" alt="No Data Image" />
          </div>
          <p class="text-center text-blue font-weight-bold">
            {{ "No Data, Kindly start your search" }}
          </p>
        </div>

        <tr v-else v-for="(item, index) in searched_data" :key="index">
          <td width="6%"><p>{{ item.id }}</p></td>
          <td width="45%"><p>{{ item.file_name }}</p></td>
          <td width="15%">
            <p class="text-muted text-center">
              {{ item.created_at | formatDate }}
            </p>
          </td>
          <td width="15%">
            <p class="text-muted text-center font-weight-bold">
              {{ item.creator.name | username }}
            </p>
          </td>
          <td
            width="20%"
            class="text-center"
            v-if="item.completedPerCentage >= 100"
          >
            <button
              id="openButton"
              class="btn btn-primary"
              @click="openCase(item.id)"
            >
              {{ "Open" }}
            </button>
          </td>
          <td width="20%" class="text-center" v-else>
            <button
              id="summarizingLoadingButton"
              class="btn btn-warning-outlined h-100"
              @click="$toast.warning('Still in progress')"
            >
              <span>
                <b>{{
                  item.completedPerCentage.toFixed(2) > 34
                    ? "Extracting details"
                  : item.completedPerCentage.toFixed(2) > 1
                    ? "Extracting key points"
                    : "Summarizing"
                }}:</b>
                <br/>
              </span>
              <span class="text-center">
                {{ item.completedPerCentage.toFixed(2) }} %
                <b-spinner label="Spinning" class="spinner" />
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div style="height: 2.5rem;">
      <b-pagination
        v-if="searched_data.length !== 0 && rows > 10"
        class="float-right mt-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        last-number
      ></b-pagination>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import Auth from "@/store/Auth.js";
import CaseSummarizer from "@/store/CaseSummarizer.js";
import { Authenticate, createFormData, loopUpdate, validateInput } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      rows: 0,
      perPage: 10,
      currentPage: 1,
      pageLoading: true,
      selectedFileName: "",
      searchQuery: "",
      searched_data: [],

      loop: false,
    };
  },

  methods: {
    onInput() {
      if (this.searchQuery.length === 0) {
        this.searchClients();
      }
    },

    searchClients() {
      this.currentPage = 1;
      this.pageLoading = true;
      this.searched_data = [];

      const queryObj = { page: this.currentPage };
      if (this.searchQuery.length !== 0) {
        queryObj.file_name = this.searchQuery;
      }

      this.$router.push({ query: queryObj });
      this.loadData();
    },

    toastError(flag) {
      this.$toast.error({
        "invalid": "Please input a valid file name\nWhich should not include: '/' '(' ')' ','",
        "empty": "Please input a valid file name\nWhich should not include: '/' '(' ')' ','",
        "file": "Please upload a .pdf file",
      }[flag]);
    },

    handleFileChange(event) {
      const file = validateInput(event.target.files[0], "file", ["pdf"]);
      if (!file[0]) {
        event.target.value = "";
        if (file[1] !== "empty") this.toastError("file");
      }
      this.selectedFileName = event.target.files[0]?.name ?? "";
    },

    handleFileUpload() {
      this.$validator.validateAll();
      const File = document.getElementById("formFile").files[0];
      const FileName = validateInput(this.selectedFileName, "string", ["filename"]);

      if (!File) {
        this.toastError("file"); return;
      } else if (!FileName[0]) {
        this.toastError(FileName[1]); return;
      }

      this.pageLoading = true;
      // console.log("pass"); return;
      CaseSummarizer.UploadFile(
        createFormData({
          "case_file": File,
          "file_name": FileName[1],
          "location_id": 1,
          "language": "en",
        })
      )
        .then((response) => {
          Authenticate(this);
          this.$toast.success(response.data.message);
          this.currentPage = 1;
          this.searched_data = [];
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
          this.pageLoading = false;
        });

      document.getElementById("formFile").value = "";
      this.selectedFileName = "";
    },

    openCase(data) {
      this.$router.push({
        name: "Case_Summarizer_CaseSummary",
        params: { id: data },
      });
    },

    loadData() {
      const me = this;
      function processData(data) {
        const DATA = data.data.data;
        const insert = me.searched_data.length > 0;
        me.pageLoading = false;
        me.rows = DATA.total;
        DATA.data.forEach(e => {
          const ID = me.searched_data.findIndex(old => old.id === e.id);

          if (ID > -1) {
            me.$set(me.searched_data, ID, e);
          } else if (insert) {
            me.searched_data.unshift(e);
          } else {
            me.searched_data.push(e)
          }

          if (e.completedPerCentage < 100) me.loop = true;
        })
      }

      loopUpdate(
        this,
        CaseSummarizer.GetCaseSummary,
        `&page=${this.currentPage}${
          this.searchQuery.length > 0 ? `&file_name=${this.searchQuery}` : ""
        }`,
        processData
      )
    },
  },

  watch: {
    currentPage(newPage, oldPage) {
      if (newPage == this.$route.query.page) return;

      this.pageLoading = true;
      this.searched_data = [];

      const queryObj = { page: newPage };
      if (this.searchQuery.length !== 0) {
        queryObj.file_name = this.searchQuery;
      }

      this.$router.push({ query: queryObj });
      clearTimeout(this.loop);
      this.loadData();
    },
  },

  created() {
    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page, 10);
      this.searched_data = [];
    }

    this.searchQuery = this.$route.query?.file_name ?? this.searchQuery;
    this.loadData();
  },

  destroyed() {
    clearTimeout(this.loop);
  },
};
</script>

<style scoped>
/* table */
.table {
  width: 100%;
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  padding: 2rem;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

#buttonFile {
  padding: 6px;
  width: 250px;
}

#openButton {
  padding-left: 5px;
  padding-right: 5px;
  width: 100px;
}

#summarizingLoadingButton {
  width: 100% !important;
  padding-left: 5px;
  padding-right: 5px;
  width: 200px;
  height: 60px;
}
</style>
