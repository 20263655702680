import axios from "@/axios.js";
/* eslint-disable */
export default {
  UploadFile(item) {
    console.log(item);
    return new Promise((resolve, reject) => {
      console.log(item);
      axios
        .post(`nexlaw/case-summarizer`, item, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetCaseSummary(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=case_summarizer` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  OpenSummary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
